import styles from './Loading.module.scss';
import WelcomeBear from 'src/assets/illust/welcomeBear.svg';

export default function Loading() {
    return (
        <div className={styles.Loading}>
            <img src={WelcomeBear} alt='Welcome!' />
            <div className={styles.text_container}>
                <span className={styles.text_top}>
                    Let’s get started with OpenFrame Refactor!
                </span>
                <span className={styles.text_bottom}>
                    OpenFrame Refactor is the right tool to start your
                    modernization journey.
                </span>
            </div>
            <div className={styles.loading_bar_container}>
                <div className={styles.loading_dot1} />
                <div className={styles.loading_dot2} />
                <div className={styles.loading_dot3} />
                <div className={styles.loading_dot4} />
                {/* <div className={styles.loading_bar} />
        <div className={styles.loading_bar_moving} /> */}
            </div>
        </div>
    );
}
