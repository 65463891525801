import './CreateDeployment.scss';

import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userContext } from 'src/router/RouterGuard';

import axiosUtil from 'src/utils/axiosUtil';
import useShake from 'src/hooks/useShake';

import Loading from 'src/components/organisms/Loading/Loading';
import LeftLabelInput from 'src/components/atoms/LeftLabelInput/LeftLabelInput';
import CheckSelectBox from 'src/components/atoms/CheckSelectBox/CheckSelectBox';
import CreateDeploymentCard from 'src/components/organisms/CreateDeploymentCard/CreateDeploymentCard';
import CreateFreeDeploymentCard from 'src/components/organisms/CreateFreeDeploymentCard/CreateFreeDeploymentCard';
import ServiceModelSpecPopup from 'src/components/molecules/ServiceModelSpecPopup/ServiceModelSpecPopup';
import CommitButton from 'src/components/atoms/CommitButton/CommitButton';
import CommitBar from 'src/components/molecules/CommitBar/CommitBar';

import AsteriskIcon from 'src/assets/icons/asterisk';

const CreateDeployment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useContext(userContext);
    const { shake, shakeAlert } = useShake();

    const [loading, setLoading] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);

    const [deploymentMaxLength, setDeploymentMaxLength] = useState(0);
    const [deploymentName, setDeploymentName] = useState('');
    const [duplicateMessage, setDuplicateMessage] = useState('');
    const [type, setType] = useState('Type 1');
    const [tier, setTier] = useState('Tier 1');
    const [cloudProvider, setCloudProvider] = useState('Amazon Web Service');
    const [region, setRegion] = useState('');

    const isFree = location.pathname.includes('free');
    const MAX_DATABASE_NAME_LENGTH = 63;

    const awsRegion = {
        // 'N.Virginia (us-east-1)': 'us-east-1',
        ...(process.env.REACT_APP_PROFILE === 'production'
            ? { 'Tokyo (ap-northeast-1)': 'ap-northeast-1' }
            : { 'Seoul (ap-northeast-2)': 'ap-northeast-2' }),
    };
    const azureRegion = {
        'Tokyo (japaneast)': 'japaneast',
    };
    const types = {
        'Type 1': 'OF Analyzer',
        'Type 2': 'OF Data Migration',
        'Type 3': 'OF Transformation',
        'Type 4': 'OF Transformation TS',
    };
    const tiers = {
        'Tier 1': '1',
        'Tier 2': '2',
        'Tier 3': '3',
    };

    const requestCloudProvider = isFree
        ? {
              'Amazon Web Service': 'aws',
          }
        : {
              'Amazon Web Service': 'aws',
              'Microsoft Azure': 'azure',
          };

    const handleClickClose = () => {
        setPopupOpen(false);
    };

    const validateValue = (inputValue) => {
        // 첫 글자 항상 소문자 시작
        if (!/^[a-z]/.test(inputValue[0])) {
            shakeAlert();
            return '';
        }

        // 유효한 문자만 입력 가능하게 처리
        const validValue = inputValue.replace(/[^a-z0-9_$]/g, (match) => {
            if (match) shakeAlert();
            return '';
        });

        // 길이 검사
        if (validValue.length > deploymentMaxLength) {
            shakeAlert();
        }

        return validValue;
    };

    const handleChangeDeploymentName = (e) => {
        const inputValue = e.target.value;
        const validationValue = validateValue(inputValue);
        setDeploymentName(validationValue);
        setDuplicateMessage('');
    };

    const handleRegionChange = (e) => {
        setRegion(e.target.value);
    };

    const handleCloudProviderChange = (e) => {
        setCloudProvider(e.target.value);

        if (e.target.value === 'Microsoft Azure') {
            setRegion('Tokyo (japaneast)');
        }
    };

    const MoveToDeploymentPage = () => {
        localStorage.setItem(
            'ToastMessage',
            `Thank you for submitting the request.\nYour deployment will be created shortly.`
        );
        navigate('/deployment');
    };

    const createFreeDeployment = async () => {
        const requestUrl = `/mail/authorized/deployment`;
        const requestBody = {
            request_type: 'CREATE',
            deployment_name: `${user.company_name}_${deploymentName}`,
            stack_name: `${user.company_name}_${deploymentName}`,
            region: awsRegion[region],
            option: 'OF Analyzer',
            tier: '1',
            free: true,
            cloud_provider: requestCloudProvider[cloudProvider],
        };

        try {
            await axiosUtil.post(requestUrl, requestBody);
            MoveToDeploymentPage();
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                const errorMessage = error.response.data.message;
                if (errorMessage.includes('already exists')) {
                    setDuplicateMessage(errorMessage);
                }
            }
            setLoading(false);
        }
    };

    const createDeployment = async () => {
        const requestUrl = `/mail/authorized/deployment`;

        const requestBody = {
            request_type: 'CREATE',
            deployment_name: `${user.company_name}_${deploymentName}`,
            stack_name: `${user.company_name}_${deploymentName}`,
            region:
                cloudProvider === 'Amazon Web Service'
                    ? awsRegion[region]
                    : azureRegion[region],
            option: types[type],
            tier: tiers[tier],
            free: false,
            cloud_provider: requestCloudProvider[cloudProvider],
        };

        try {
            await axiosUtil.post(requestUrl, requestBody);
            MoveToDeploymentPage();
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                const errorMessage = error.response.data.message;
                if (errorMessage.includes('already exists')) {
                    setDuplicateMessage(errorMessage);
                }
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            setDeploymentMaxLength(
                MAX_DATABASE_NAME_LENGTH - `${user.company_name}-`.length
            );
        }
    }, [user]);

    useEffect(() => {
        if (process.env.REACT_APP_PROFILE === 'production') {
            setRegion('Tokyo (ap-northeast-1)');
        } else {
            setRegion('Seoul (ap-northeast-2)');
        }
    }, []);

    if (loading) {
        return (
            <div className='loading_screen'>
                <Loading />
            </div>
        );
    }

    return (
        <div className='CreateDeployment'>
            <div style={{ display: 'flex' }}>
                <LeftLabelInput
                    label={'Deployment Name'}
                    isRequired={true}
                    value={deploymentName}
                    onChangeHandler={handleChangeDeploymentName}
                />
            </div>
            <div className='help'>
                <div
                    className={`deployment-name-valication ${shake ? 'shake' : ''}`}
                >
                    Must begin with a lowercase letter, allows special
                    characters '_' and '$', up to {deploymentMaxLength}{' '}
                    characters.
                </div>
                <div className='duplicate-name-text shake'>
                    {duplicateMessage}
                </div>
            </div>

            <div className='ServiceModel'>
                <div className='service-model_container'>
                    <div className='text_container'>
                        Service Model
                        <div className='asterisk'>
                            <AsteriskIcon />
                        </div>
                    </div>
                    {/* {!isFree && (
                        <div
                            className='service-popup-text'
                            onClick={() => {
                                setPopupOpen(true);
                            }}
                        >
                            View all Service Model Specification
                        </div>
                    )} */}
                </div>
                <div className='card_container'>
                    {isFree ? (
                        <CreateFreeDeploymentCard />
                    ) : (
                        <CreateDeploymentCard
                            type={type}
                            setType={setType}
                            tier={tier}
                            setTier={setTier}
                        />
                    )}
                </div>
            </div>
            <div className='selectbox_container'>
                <div className='text_container'>
                    Cloud Service Provider
                    <div className='asterisk'>
                        <AsteriskIcon />
                    </div>
                </div>
                <CheckSelectBox
                    options={Object.keys(requestCloudProvider)}
                    onChangeHandler={handleCloudProviderChange}
                    disabledOption={'Microsoft Azure'}
                />
            </div>
            <div className='selectbox_container'>
                <div className='text_container'>
                    Region
                    <div className='asterisk'>
                        <AsteriskIcon />
                    </div>
                </div>
                <CheckSelectBox
                    options={Object.keys(
                        cloudProvider === 'Amazon Web Service'
                            ? awsRegion
                            : azureRegion
                    )}
                    onChangeHandler={handleRegionChange}
                />
            </div>

            <CommitBar>
                <div className='button_container'>
                    <CommitButton
                        content='Send Request'
                        onClick={
                            isFree ? createFreeDeployment : createDeployment
                        }
                        disabled={
                            deploymentName.trim() === '' ||
                            deploymentName.length > deploymentMaxLength
                        }
                        styleProps={{ minWidth: '128px' }}
                    />
                    <CommitButton
                        content='Cancel'
                        level='secondary'
                        onClick={() => navigate(-1)}
                    />
                </div>
            </CommitBar>
            {popupOpen && (
                <ServiceModelSpecPopup onClickClose={handleClickClose} />
            )}
        </div>
    );
};

export default CreateDeployment;
