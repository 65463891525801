import style from './RequestCard.module.scss';

import React from 'react';

import DeploymentStatusChip from 'src/components/molecules/DeploymentStatusChip';
import FreeTrial from 'src/components/atoms/FreeTrial/FreeTrial';

const RequestCard = ({ content, selected, onClick: handleClick }) => {
    const { stack_name, deployment_name, tier, free, option } = content;

    const selectedStyle = selected === stack_name ? 'selected' : '';

    return (
        <div
            className={`${style.RequestCard} ${style[selectedStyle]}`}
            onClick={handleClick}
        >
            <div className={style.header}>
                <div className={style.title}>
                    <span className={style.name}>{deployment_name}</span>
                    <DeploymentStatusChip type={'REQUESTED'} />
                </div>
                <div className={style.sub_title}>{option}</div>
            </div>
            <div className={style.price}>
                <div className={style.item}>
                    <div>amount</div>
                    <div className={style.value}>
                        <div>-</div>
                        {free && <FreeTrial />}
                    </div>
                </div>
                <div className={style.item}>
                    <div>Tier</div>
                    <div className={style.value}>{tier}</div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(RequestCard);
