import { useState } from 'react';

import './CreateDeploymentCard.scss';

import { ReactComponent as InfoPurpleIcon } from 'src/assets/icons/infoPurpleIcon.svg';
import { ReactComponent as InfoGrayIcon } from 'src/assets/icons/infoGrayIcon.svg';
import { ReactComponent as CircleIcon } from 'src/assets/icons/circle.svg';
import { ReactComponent as CheckPurpleIcon } from 'src/assets/icons/checkPurpleIcon.svg';

const CreateDeploymentCard = ({
    type: selectedType,
    setType: setSelectedType,
    tier: selectedTier,
    setTier: setSelectedTier,
}) => {
    const types = ['Type 1', 'Type 2', 'Type 3', 'Type 4'];
    const titles = {
        'Type 1': 'OF Analyzer',
        'Type 2': 'OF Data Migration',
        'Type 3': 'OF Transformation',
        'Type 4': 'OF Transformation TS',
    };
    const titleDescriptions = {
        'Type 1': 'Automated Legacy Asset Analysis',
        'Type 2': 'Automated Legacy Data Transformation',
        'Type 3': 'Automated Legacy Asset Transformation',
        'Type 4': 'Legacy Asset Transformation',
    };
    const descriptions = {
        'Type 1':
            'OF Analyzer analyzes Mainframe legacy resources in order to determine system or application architecture',

        'Type 2':
            'OF Data Migration automatically migrates to tables in the RDB based on the dataset layout information automatically analyzed in OpenFrame Analyzer.',

        'Type 3':
            'OF Transformation automatically migrates legacy assets such as COBOL to optimized Java with TmaxSoft’s proprietary compiler technology.',

        'Type 4':
            'OF Transformation TS migrates legacy assets such as COBOL to optimized Java with TmaxSoft’s proprietary compiler technology and professional service.',
    };
    const tiers = ['Tier 1', 'Tier 2', 'Tier 3'];

    const renderCard = (type) => {
        if (type === selectedType) {
            return (
                <div className='card_layout card_active' key={type}
                    onClick={() => {
                        setSelectedType(type);
                        // setSelectedTier(tier);
                    }}
                >
                    <div className='title_container'>
                        <div className='type'>{type}</div>
                        <div className='title'>{titles[type]}</div>
                        <div className='title_description'>
                            {titleDescriptions[type]}
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='description'>{descriptions[type]}</div>
                </div>
            );
        } else {
            return (
                <div className='card_layout card_disabled' key={type}
                onClick={() => {
                    setSelectedType(type);
                    // setSelectedTier(tier);
                }}
                >
                    <div className='title_container'>
                        <div className='type disabled type_disabled'>
                            {type}
                        </div>
                        <div className='title disabled'>{titles[type]}</div>
                        <div className='title_description disabled'>
                            {titleDescriptions[type]}
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='description'>{descriptions[type]}</div>
                </div>
            );
        }
    };

    return <>{types.map((type) => renderCard(type))}</>;
};

export default CreateDeploymentCard;
