// CSS
import './SimpleModal.css';

import { useEffect, useRef, useState } from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/icons/closeIcon.svg';

import CommitButton from 'src/components/atoms/CommitButton/CommitButton';

export const preventScroll = () => {
    const currentScrollY = window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.top = `-${currentScrollY}px`;
    document.body.style.overflowY = 'scroll';
    return currentScrollY;
};

export const allowScroll = (prevScrollY) => {
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.top = '';
    document.body.style.overflowY = '';
    window.scrollTo(0, prevScrollY);
};

function SimpleModal({
    title = 'title',
    children,
    onClickClose,
    onClickOK,
    okButtonDisabled = false,
    cancelButtonText = 'Cancel',
    okButtonText = 'Confirm',
    size = 'sm',
    commitButton,
}) {
    const modalRef = useRef(null);
    const [isMouseOn, setIsMouseOn] = useState(false);

    // closes the modal when the backdrop is clicked
    useEffect(() => {
        const handler = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClickClose();
            }
        };

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    // prevents scrolling under backdrop
    useEffect(() => {
        const prevScrollY = preventScroll();
        return () => {
            allowScroll(prevScrollY);
        };
    }, []);

    return (
        <div className='SimpleModal'>
            <div className='backdrop' />
            <div className={`dialog_container ${size}`} ref={modalRef}>
                {/* Header */}
                <div className='header'>
                    <p>{title}</p>
                    <button
                        onClick={onClickClose}
                        onMouseOver={() => {
                            setIsMouseOn(true);
                        }}
                        onMouseLeave={() => {
                            setIsMouseOn(false);
                        }}
                    >
                        <div className='icon'>
                            <CloseIcon />
                        </div>
                    </button>
                </div>

                <div className='body'>{children}</div>

                {/* Footer */}
                <div className='footer'>
                    <CommitButton
                        level='secondary'
                        content={cancelButtonText}
                        onClick={onClickClose}
                    />
                    {commitButton || (
                        <CommitButton
                            content={okButtonText}
                            onClick={onClickOK}
                            disabled={okButtonDisabled}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SimpleModal;
