// CSS
import styles from './Header.module.scss';

// Assets
import NotificationIcon from '../../../assets/icons/notifications';
import AccountCircleIcon from '../../../assets/icons/account-circle';
import useClickAway from '../../../hooks/useClickAway';

// Hooks
import { useLocation } from 'react-router-dom';

// Components
import Breadcrumb from '../../molecules/Breadcrumb/Breadcumb';
import UserInfo from '../../molecules/UserInfo/UserInfo';

const makeBreadCrumbList = (params) => {
    const slicedParams = params.slice(0, 1) === '/' ? params.slice(1) : params;
    const paramsArray = slicedParams.split('/');
    return paramsArray.map((param, idx, arr) => {
        let location = '/' + paramsArray[0];

        for (let i = 1; i < idx + 1; i++) {
            location = location + '/' + paramsArray[i];
        }

        return {
            itemName: param,
            property: idx === arr.length - 1 ? 'current' : 'default',
            location: location,
        };
    });
};

export default function Header() {
    const params = useLocation().pathname;

    const breadCrumbList = makeBreadCrumbList(params);
    const currentMenu = breadCrumbList[breadCrumbList.length - 1];

    const previousLayoutMenu = [
        'dashboard',
        'monitoring',
        'service_management',
        'settings',
    ];

    const {
        isClicked: isAccountIconClick,
        clickRef: accountRef,
        onToggle: onAccountToggle,
    } = useClickAway();

    const {
        isClicked: isNotificationIconClick,
        clickRef: notificationRef,
        onToggle: onNotificationToggle,
    } = useClickAway();

    return (
        <div
            className={`${styles.container} ${
                previousLayoutMenu.includes(currentMenu.itemName)
                    ? styles.previous
                    : ''
            }`}
        >
            <div
                className={`${styles.wrapper} ${
                    previousLayoutMenu.includes(currentMenu.itemName)
                        ? styles.previous
                        : ''
                }`}
            >
                <div className={styles.title_wrapper}>
                    <div className={styles.title}>{currentMenu.itemName}</div>

                    <Breadcrumb
                        items={
                            breadCrumbList.length < 2
                                ? [currentMenu]
                                : breadCrumbList
                        }
                    />
                </div>

                <div className={styles.button_container}>
                    <div ref={notificationRef}>
                        <button
                            className={`${styles.icon} ${
                                isNotificationIconClick ? styles.active : ''
                            }`}
                            onClick={onNotificationToggle}
                        >
                            <NotificationIcon />
                        </button>
                    </div>

                    <div ref={accountRef}>
                        <button
                            className={`${styles.icon} ${
                                isAccountIconClick ? styles.active : ''
                            }`}
                            onClick={onAccountToggle}
                        >
                            <AccountCircleIcon />
                        </button>
                        <UserInfo isClicked={isAccountIconClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}
