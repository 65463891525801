import styles from './OverviewDeploymentEmpty.module.scss';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'src/assets/icons/add.svg';
import NoDeploymentIllust from 'src/assets/illust/overviewNoDeployment.svg';

import BasicButton from 'src/components/atoms/BasicButton/BasicButton';

export default function OverviewDeploymentEmpty({ user }) {
    const navigate = useNavigate();

    return (
        <div className={styles.OverviewDeploymentEmpty}>
            <div className={styles.deployment_container}>
                <img
                    src={NoDeploymentIllust}
                    alt='No Deployment'
                    width={'20%'}
                />
                <div className={styles.bottom_division}>
                    <div className={styles.text_container}>
                        <span className={styles.text_black}>
                            Let’s get started with OpenFrame Refactor!
                        </span>
                        <span className={styles.text_grey}>
                            There’re no deployment for you to see yet.
                        </span>

                        <span className={styles.text_grey}>
                            If you want to create new deployment, just click and
                            go ahead.
                        </span>
                    </div>

                    <div className={styles.button_container}>
                        {/* {user.free_plan_availability && (
                            <BasicButton
                                content='Try for Free'
                                type='outline'
                                styleProps={{ width: '180px' }}
                                onClick={() => navigate('/deployment/free')}
                            />
                        )} */}
                        <BasicButton
                            content='Create Deployment'
                            startIcon={<AddIcon />}
                            styleProps={{ width: '180px' }}
                            onClick={() => navigate('/deployment/create')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
