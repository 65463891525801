import styles from "./ServiceModelSpecPopup.module.scss";

// Assets
import { ReactComponent as CloseIcon } from "src/assets/icons/closeIcon.svg";

export default function ServiceModelSpecPopup({ onClickClose }) {
  return (
    <div className={styles.ServiceModelSpecPopup}>
      <div className={styles.title_wrapper}>
        <div className={styles.title}>Service Model Specification</div>
        <CloseIcon onClick={onClickClose} />
      </div>
      <table>
        <thead>
          <tr>
            <th rowspan="2" className={styles.no_left_line}>
              Service
            </th>
            <th rowspan="2">Purpose</th>
            <th rowspan="2">Hardware</th>

            <th scope="col" colspan="3">
              OF Analyzer
            </th>
            <th scope="col" colspan="3">
              OF Data Migration
            </th>
            <th scope="col" colspan="3">
              OF Transformation
            </th>
            <th scope="col" colspan="3" className={styles.no_right_line}>
              OF Transformation TS
            </th>
          </tr>
          <tr>
            <th scope="col">Tier 1</th>
            <th scope="col">Tier 2</th>
            <th scope="col">Tier 3</th>

            <th scope="col">Tier 1</th>
            <th scope="col">Tier 2</th>
            <th scope="col">Tier 3</th>

            <th scope="col">Tier 1</th>
            <th scope="col">Tier 2</th>
            <th scope="col">Tier 3</th>

            <th scope="col">Tier 1</th>
            <th scope="col">Tier 2</th>
            <th scope="col" className={styles.no_right_line}>
              Tier 3
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th rowSpan="4" className={styles.no_left_line}>
              OF Analyzer
            </th>
            <td rowSpan="2">Viewing</td>
            <td>vCPU</td>
            <td>1</td>
            <td>2</td>
            <td>2</td>
            <td>1</td>
            <td>2</td>
            <td>2</td>
            <td>1</td>
            <td>2</td>
            <td>2</td>
            <td>1</td>
            <td>2</td>
            <td className={styles.no_right_line}>2</td>
          </tr>
          <tr>
            <td>RAM</td>
            <td>4GB</td>
            <td>8GB</td>
            <td>16GB</td>
            <td>4GB</td>
            <td>8GB</td>
            <td>16GB</td>
            <td>4GB</td>
            <td>8GB</td>
            <td>16GB</td>
            <td>4GB</td>
            <td>8GB</td>
            <td className={styles.no_right_line}>16GB</td>
          </tr>

          <tr>
            <td rowSpan="2">Analysis</td>
            <td>vCPU</td>
            <td>1</td>
            <td>2</td>
            <td>2</td>
            <td>1</td>
            <td>2</td>
            <td>2</td>
            <td>1</td>
            <td>2</td>
            <td>2</td>
            <td>1</td>
            <td>2</td>
            <td className={styles.no_right_line}>2</td>
          </tr>
          <tr>
            <td>RAM</td>
            <td>4GB</td>
            <td>8GB</td>
            <td>16GB</td>
            <td>4GB</td>
            <td>8GB</td>
            <td>16GB</td>
            <td>4GB</td>
            <td>8GB</td>
            <td>16GB</td>
            <td>4GB</td>
            <td>8GB</td>
            <td className={styles.no_right_line}>16GB</td>
          </tr>

          <tr>
            <th className={styles.no_left_line}>OF Dataset Manager</th>
            <td></td>

            <td>Storage</td>
            <td></td>
            <td></td>
            <td></td>
            <td>200GB</td>
            <td>500GB</td>
            <td>1TB</td>
            <td>200GB</td>
            <td>500GB</td>
            <td>1TB</td>
            <td>200GB</td>
            <td>500GB</td>
            <td className={styles.no_right_line}>1TB</td>
          </tr>

          <tr>
            <th rowSpan="2" className={styles.no_left_line}>
              OF Migrator
            </th>
            <td></td>
            <td>vCPU</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>1</td>
            <td>2</td>
            <td>2</td>
            <td></td>
            <td></td>
            <td className={styles.no_right_line}></td>
          </tr>
          <tr>
            <td></td>
            <td>RAM</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>2GB</td>
            <td>4GB</td>
            <td>8GB</td>
            <td></td>
            <td></td>
            <td className={styles.no_right_line}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
