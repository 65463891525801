import styles from './Label.module.scss';
import AsteriskIcon from '../../../assets/icons/asterisk';

export default function Label({
    name,
    isRequired = false,
    styleProps = undefined,
}) {
    return (
        <div className={styles.container}>
            <label htmlFor={name} style={{ ...styleProps }}>
                {name}
                <div className={styles.asterisk}>
                    {isRequired && <AsteriskIcon />}
                </div>
            </label>
        </div>
    );
}
