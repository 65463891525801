import './CheckSelectBox.scss';

const CheckSelectBox = ({
    options = [],
    onChangeHandler = () => {},
    disabledOption = '',
}) => {
    const defaultValue = options[0];

    return (
        <select className='CheckSelectBox' onChange={onChangeHandler}>
            {options.map((option) => (
                <option
                    key={option}
                    value={option}
                    className='optionField'
                    defaultValue={defaultValue}
                    disabled={option === disabledOption}
                >
                    <div className='option'>{option}</div>
                </option>
            ))}
        </select>
    );
};

export default CheckSelectBox;
