import s from './SignUpResendConfirmationPage.module.scss';

import { useNavigate } from 'react-router-dom';

import illustType04 from 'src/assets/illust/illustType04.svg';
import Link from 'src/components/atoms/Link/Link';
import SignUpConfirmCodeForm from 'src/components/molecules/SignUpConfirmCodeForm/SignUpConfirmCodeForm';

function SignUpResendConfirmationPage({ email, setIsConfirmationCompleted }) {
    const navigate = useNavigate();

    return (
        <div className={s.SignUpResendConfirmationPage}>
            <div className={s.card}>
                <div className={s.page_content}>
                    <img src={illustType04} alt='email-check' />
                    <h2 className={s.title}>
                        Welcome to <br /> TmaxSoft OpenFrame Refactor!
                    </h2>
                    <div className={s.description}>
                        We sent verification code to
                        <strong>{email}</strong>
                        Please check your inbox and enter the code below.
                    </div>
                    <SignUpConfirmCodeForm
                        email={email}
                        setIsConfirmationCompleted={setIsConfirmationCompleted}
                    />
                </div>
            </div>
            <Link
                text='Return to Login Page'
                type='exposed'
                onClick={() => navigate('/signin')}
                styleProps={{
                    display: 'table',
                    margin: '24px auto',
                }}
            />
        </div>
    );
}

export default SignUpResendConfirmationPage;
