import './DeleteAccountModal.scss';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import axiosUtil from 'src/utils/axiosUtil';
import authenticateUser from 'src/utils/authenticateUser';
import { clearAllLocalStorage } from 'src/utils/clearLocalStorage';

import TextField from 'src/components/atoms/TextField/TextField';
import SimpleModal from 'src/components/molecules/SimpleModal/SimpleModal';
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';

const DeleteAccountModal = ({ email, setIsDeleteAccountModalOpen }) => {
    const navigate = useNavigate();

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [existDeployment, setExistDeployment] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleClickClose = () => {
        setPassword('');
        setPasswordError('');
        setExistDeployment(false);
        setIsDeleteAccountModalOpen(false);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setButtonDisabled(e.target.value === '');
        setPasswordError('');
    };

    const handleLogOut = () => {
        clearAllLocalStorage();
        navigate('/signin');
    };

    const deleteAccount = async () => {
        const requestUrl = `/users/${email}`;
        const requestBody = {
            access_token: localStorage.getItem('OpenFrameAccessToken'),
        };

        try {
            await axiosUtil.delete(requestUrl, {
                data: requestBody,
            });
            handleLogOut();
            setIsDeleteAccountModalOpen(false);
        } catch (error) {
            if (
                error.response.data.message ===
                'Please delete all deployments first'
            ) {
                setExistDeployment(true);
                setButtonDisabled(true);
            }
        }
    };

    const handleClickDeleteButton = async () => {
        const { success: isAuthenticate } = await authenticateUser(
            email,
            password
        );

        if (isAuthenticate) {
            deleteAccount();
        } else {
            setPasswordError('Your password is incorrect. Please try again.');
        }
    };

    const deleteAccountModalContent = (
        <div className='accountDeleteModalContent'>
            <div className='question'>Are you sure?</div>
            <div className='description'>
                You will lose access to your OpenFrame account once your
                deletion request has been submitted.
            </div>
            {existDeployment && (
                <div style={{ marginTop: '3px' }}>
                    Delete all Deployment before deleting the account.
                </div>
            )}
            <div className='email'>{email}</div>
            <div style={{ padding: '8px 0' }}>
                <TextField
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChangeHandler={(e) => {
                        handlePasswordChange(e);
                    }}
                    errorMessage={passwordError}
                />
            </div>
        </div>
    );

    return (
        <SimpleModal
            title='Delete Account'
            children={deleteAccountModalContent}
            onClickClose={() => {
                handleClickClose();
            }}
            commitButton={
                <BasicButton
                    level='delete'
                    content='Delete'
                    onClick={handleClickDeleteButton}
                    disabled={buttonDisabled}
                    styleProps={{ padding: '4px 16px' }}
                />
            }
        />
    );
};

export default DeleteAccountModal;
