import './LogoTitleDescriptionForm.scss';

const LogoTitleDescriptionForm = ({
    title = '',
    logo = undefined,
    description = '',
}) => {
    return (
        <div className='LogoTitleDescriptionForm'>
            <img src={logo} alt='logo' />
            <h2 className='title'>{title}</h2>
            <h3>
                {description.split('\n').map((text, idx) => (
                    <div key={idx}>
                        {text}
                        <br />
                    </div>
                ))}
            </h3>
        </div>
    );
};

export default LogoTitleDescriptionForm;
