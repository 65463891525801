import CopyrightLogo from 'src/components/molecules/CopyrightLogo';
import './PreAuthenticationLayout.scss';

import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

// layout
const PreAuthenticationLayout = () => {
    const location = useLocation();
    const pathName = location.pathname;
    const oneColorBGPath = ['forgot_password', 'signup'];
    const [isOneColorBG, setIsOneColorBG] = useState(false);

    useEffect(() => {
        const isOneColor = oneColorBGPath.some((el) => pathName.includes(el));
        setIsOneColorBG(() => isOneColor);
    }, [location]);

    return (
        <div
            className={`PreAuthenticationLayout ${isOneColorBG && 'one_color'}`}
        >
            {!isOneColorBG && <div className='top_layer' />}
            <div className='PreAuthenticationLayout__outlet'>
                <Outlet />
            </div>
            <div
                className={`PreAuthenticationLayout__footer ${
                    isOneColorBG && 'relative'
                }`}
            >
                <CopyrightLogo isOneColorBG={isOneColorBG} />
            </div>
        </div>
    );
};

export default PreAuthenticationLayout;
