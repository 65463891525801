import s from './NotificationToast.module.scss';

import { useEffect, useRef } from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/icons/closeIcon.svg';
import IconButton from '../IconButton/IconButton';

function NotificationToast({ content, onClickClose, styleProps }) {
    const toastRef = useRef();

    const fadeOutToast = () => {
        toastRef.current?.classList.add(`${s.slideout}`);
    };

    const autoFadeOutToast = () => {
        setTimeout(fadeOutToast, 5000);
        setTimeout(onClickClose, 6000);
    };

    const onClickCloseButton = () => {
        fadeOutToast();
        setTimeout(onClickClose, 6000);
    };

    useEffect(() => {
        autoFadeOutToast();
    }, []);

    return (
        <div
            className={s.NotificationToast}
            ref={toastRef}
            style={{ ...styleProps }}
        >
            <div className={s.notificationToastContent}>{content}</div>
            <IconButton
                icon={<CloseIcon />}
                level='secondary'
                type='ghost'
                size='sm'
                theme='dark'
                onClick={onClickCloseButton}
            />
        </div>
    );
}

export default NotificationToast;
