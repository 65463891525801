import axiosUtil from './axiosUtil';

export async function getDeploymentDestination(user) {
    const isNoDeployment = await checkDeployments(user);

    if (isNoDeployment) {
        return '/deployment/create';
    } else {
        return '/deployment';
    }
}

const checkDeployments = async (user) => {
    const isNoDeployment = await axiosUtil
        .get(`/deployments/list?user_id=${user.user_name}`)
        .then((data) => {
            const userData = data.data;
            const requests = userData.requests ?? [];
            const deployments = userData.deployments ?? [];

            return requests.length === 0 && deployments.length === 0;
        })
        .catch(() => {
            return true;
        });

    return isNoDeployment;
};
