import './ResetPassword.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useShake from 'src/hooks/useShake';
import axiosUtil from 'src/utils/axiosUtil';

import keyIllust from 'src/assets/illust/keyIllust.svg';

import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import TopLabelInput from 'src/components/atoms/TopLabelInput/TopLabelInput';
import LogoTitleDescriptionForm from 'src/components/molecules/LogoTitleDescriptionForm/LogoTitleDescriptionForm';

const ResetPassword = ({ email }) => {
    const navigate = useNavigate();
    const { shake, shakeAlert } = useShake();

    // Confirm Code
    const [code, setCode] = useState();

    // Password
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();

    // Password validation
    const [hasMinimumLength, setHasMinimumLength] = useState(false);
    const [hasUpperLowerCase, setHasUpperLowerCase] = useState(false);
    const [hasNumbers, setHasNumbers] = useState(false);
    const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);
    const [hasSamePassword, setHasSamePassword] = useState(false);

    // Error Message
    const [warnMessage, setWarnMessage] = useState();
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');

    const onCodeFieldChange = (e) => {
        returnConfirmCodeValue(e.target.value.replace(/[^0-9]/g, ''));
    };

    const returnConfirmCodeValue = (value) => {
        setCode(value);
    };

    const passwordConditions = [
        {
            text: '- Must contain an uppercase and a lowercase letter (A,z)',
            condition: hasUpperLowerCase,
        },
        {
            text: '- Must be at least 12 characters long.',
            condition: hasMinimumLength,
        },
        {
            text: '- Must contain a number',
            condition: hasNumbers,
        },
        {
            text: '- Must contain a special character (!, %, @, #, etc.)',
            condition: hasSpecialCharacters,
        },
    ];

    const checkPasswordRule = (password) => {
        const minumumLength = 12;
        setHasMinimumLength(minumumLength <= password.length);
        setHasUpperLowerCase(/[A-Z]/.test(password) && /[a-z]/.test(password));
        setHasNumbers(/\d/.test(password));
        setHasSpecialCharacters(
            /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=|\\'\\"]/g.test(password)
        );
    };

    const checkPasswordIsSame = (value, compareTarget) => {
        const hasSame = value === compareTarget;
        setHasSamePassword(hasSame);
        changeConfirmNewPasswordMessage(hasSame);
    };

    const onNewPasswordFieldChange = (e) => {
        setNewPassword(e.target.value);
        checkPasswordRule(e.target.value);
        checkPasswordIsSame(e.target.value, confirmNewPassword);
    };

    const onConfirmNewPasswordFieldChange = (e) => {
        setConfirmNewPassword(e.target.value);
        checkPasswordIsSame(e.target.value, newPassword);
    };

    const changeConfirmNewPasswordMessage = (hasSame) => {
        if (confirmNewPassword) {
            if (hasSame) {
                setConfirmNewPasswordError('');
            } else {
                setConfirmNewPasswordError(
                    'The password confirmation does not match.'
                );
            }
        }
    };

    const handleClickSave = () => {
        const resetPasswordData = {
            confirm_code: code,
            user_name: email,
            password: newPassword,
        };

        axiosUtil({
            url: `/auth/password`,
            method: 'PATCH',
            data: resetPasswordData,
        })
            .then((res) => {
                localStorage.setItem(
                    'ToastMessage',
                    'Your password has been changed successfully'
                );
                navigate('/signin');
            })
            .catch((err) => {
                const message = err.response.data.message;
                switch (message) {
                    case 'Invalid verification code provided please try again.':
                    case 'Attempt limit exceeded please try after some time.':
                        setWarnMessage(message);
                        shakeAlert();
                        break;
                    default:
                        alert(message ?? 'Reset Password Error.');
                }
            });
    };

    const handleClickResend = () => {
        axiosUtil({
            url: `/auth/password`,
            method: 'POST',
            data: {
                user_name: email,
            },
        })
            .then((res) => {
                alert('Success Resend Code.');
            })
            .catch((err) => {
                alert('Failed to resend Code.');
            });
    };

    return (
        <>
            <div className='ResetPassword content_wrapper'>
                <div className='box'>
                    <LogoTitleDescriptionForm
                        title={'Reset Password'}
                        logo={keyIllust}
                        description={
                            'Enter a new password to reset the password on your \n account. We’ll ask for this password whenever you \n log in.'
                        }
                    />

                    <div className='field_container'>
                        <TopLabelInput
                            // inputType={'number'}
                            label={'Code'}
                            targetId={'code'}
                            placeholder={'Code'}
                            onChangeHandler={onCodeFieldChange}
                            value={code}
                            inputStyle={{ width: '336px' }}
                        />

                        <>
                            {warnMessage && (
                                <div
                                    className={`warning_text ${shake ? 'shake' : ''}`}
                                >
                                    <span>{warnMessage}</span>
                                </div>
                            )}
                            <div className='text align-end'>
                                <span>Haven't you received the mail yet?</span>
                                <span
                                    className='resend_code'
                                    onClick={handleClickResend}
                                >
                                    Resend Code
                                </span>
                            </div>
                        </>
                    </div>
                    <div className='field_container'>
                        <TopLabelInput
                            isRequired
                            label={'New password'}
                            targetId={'password'}
                            inputType={'password'}
                            placeholder={'please enter password'}
                            onChangeHandler={onNewPasswordFieldChange}
                            value={newPassword}
                            inputStyle={{ width: '336px' }}
                        />

                        <div className='warning_container'>
                            {passwordConditions.map((el, idx) => (
                                <span
                                    className={el.condition && 'green'}
                                    key={idx}
                                >
                                    {el.text}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className='field_container'>
                        <TopLabelInput
                            isRequired
                            label={'Confirm new password'}
                            targetId={'confirm new password'}
                            inputType={'password'}
                            placeholder={'please confirm your password'}
                            onChangeHandler={onConfirmNewPasswordFieldChange}
                            value={confirmNewPassword}
                            inputStyle={{ width: '336px' }}
                        />

                        {confirmNewPasswordError && (
                            <div className={`warning_text`}>
                                {confirmNewPasswordError}
                            </div>
                        )}
                    </div>
                    <BasicButton
                        content='Save'
                        styleProps={{
                            width: '336px',
                            height: '44px',
                        }}
                        onClick={handleClickSave}
                        disabled={
                            !(
                                hasMinimumLength &&
                                hasUpperLowerCase &&
                                hasNumbers &&
                                hasSpecialCharacters &&
                                hasSamePassword &&
                                code &&
                                newPassword &&
                                confirmNewPassword
                            )
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
