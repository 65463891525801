import style from './DeploymentCard.module.scss';

import React from 'react';

import FreeTrial from 'src/components/atoms/FreeTrial/FreeTrial';
import DeploymentStatusChip from 'src/components/molecules/DeploymentStatusChip';

const DeploymentCard = ({ content, selected, onClick: handleClick }) => {
    const {
        tenant_name,
        deployment_name,
        tier,
        stack_status,
        deleted = false,
        free,
        billing,
        option,
    } = content;

    const selectedStyle = selected === tenant_name ? 'selected' : '';

    return (
        <div
            className={`${style.DeploymentCard} ${style[selectedStyle]}`}
            onClick={handleClick}
        >
            <div className={style.header}>
                <div className={style.title}>
                    <span className={style.name}>{deployment_name}</span>
                    <DeploymentStatusChip
                        type={stack_status}
                        deleted={deleted}
                    />
                </div>
                <div className={style.sub_title}>{option}</div>
            </div>
            <div className={style.price}>
                <div className={style.item}>
                    <div>amount</div>
                    <div className={style.value}>
                        <div>${billing}</div>
                        {free && <FreeTrial />}
                    </div>
                </div>
                <div className={style.item}>
                    <div>Tier</div>
                    <div className={style.value}>{tier}</div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(DeploymentCard);
