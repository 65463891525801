import './CreateFreeDeploymentCard.scss';

import { ReactComponent as InfoPurpleIcon } from 'src/assets/icons/infoPurpleIcon.svg';
import { ReactComponent as InfoGrayIcon } from 'src/assets/icons/infoGrayIcon.svg';
import { ReactComponent as CheckPurpleIcon } from 'src/assets/icons/checkPurpleIcon.svg';

const CreateFreeDeploymentCard = () => {
    const types = ['Type 1', 'Type 2', 'Type 3', 'Type 4'];
    const titles = {
        'Type 1': 'OF Analyzer Basic',
        'Type 2': 'OF Data Migration',
        'Type 3': 'OF Transformation',
        'Type 4': 'OF Transformation TS',
    };
    const titleDescriptions = {
        'Type 1': 'Automated Legacy Asset Analysis',
        'Type 2': 'Automated Legacy Data Transformation',
        'Type 3': 'Automated Legacy Asset Transformation',
        'Type 4': 'Legacy Asset Transformation',
    };
    const descriptions = {
        'Type 1':
            'OF Analyzer analyzes Mainframe legacy resources in order to determine system or application architecture',

        'Type 2':
            'OF Data Migration automatically migrates to tables in the RDB based on the dataset layout information automatically analyzed in OpenFrame Analyzer.',

        'Type 3':
            'OF Transformation automatically migrates legacy assets such as COBOL to optimized Java with TmaxSoft’s proprietary compiler technology.',

        'Type 4':
            'OF Transformation TS migrates legacy assets such as COBOL to optimized Java with TmaxSoft’s proprietary compiler technology and professional service.',
    };

    const renderCard = (type) => {
        if (type === 'Type 1') {
            return (
                <div className='free-card_layout card_active'>
                    <div className='title_container'>
                        <div className='type'>{type}</div>
                        <div className='title'>{titles[type]}</div>
                        <div className='title_description'>
                            {titleDescriptions[type]}
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='description'>{descriptions[type]}</div>
                    <div className='tier_container tier_border'>
                        <div className='icon'>
                            <CheckPurpleIcon />
                        </div>
                        <div className='text_container'>
                            <div className='type'>OF Analyzer</div>
                            <div className='spec'>
                                <div>Viewing Service : 1 vCPU, 4GB RAM </div>
                                <div>Analysis Service : 1 vCPU, 2GB RAM</div>
                            </div>
                            <div className='limit'>limited for 20MB upload</div>
                        </div>
                    </div>
                    <div className='info_container'>
                        <InfoPurpleIcon />
                        free for 90 days
                    </div>
                </div>
            );
        } else {
            return (
                <div className='free-card_layout card_disabled'>
                    <div className='title_container'>
                        <div className='type disabled type_disabled'>
                            {type}
                        </div>
                        <div className='title disabled'>{titles[type]}</div>
                        <div className='title_description disabled'>
                            {titleDescriptions[type]}
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='description'>{descriptions[type]}</div>
                    <div className='tier_container'></div>
                    <div className='info_container disabled'>
                        <InfoGrayIcon />
                        does not provide free trial
                    </div>
                </div>
            );
        }
    };

    return <>{types.map((type) => renderCard(type))}</>;
};

export default CreateFreeDeploymentCard;
