import axiosUtil from './axiosUtil';

export async function getUser() {
    const user = await axiosUtil({
        url: '/users',
        method: 'GET',
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            alert(err);
        });

    return user;
}
