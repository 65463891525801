// CSS
import './ForgotPassword.scss';

// Hooks
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Assets
import keyIllust from 'src/assets/illust/keyIllust.svg';
import expiredIllust from 'src/assets/illust/expiredIllust.svg';

// Utils
import axiosUtil from 'src/utils/axiosUtil';
import { checkEmailFormat } from 'src/utils/checkFormat';

// Components
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import Link from 'src/components/atoms/Link/Link';
import TopLabelInput from 'src/components/atoms/TopLabelInput/TopLabelInput';
import LogoTitleDescriptionForm from 'src/components/molecules/LogoTitleDescriptionForm/LogoTitleDescriptionForm';
import ResetPassword from 'src/pages/ResetPassword/ResetPassword';

function ForgotPassword({ expired: isExpired = false }) {
    const navigate = useNavigate();

    // state for input fields
    const [email, setEmail] = useState('');

    // state for validation
    const [isSendCode, setIsSendCode] = useState(false);
    const [isInvalidateEmailFormat, setIsInvalidateEmailFormat] =
        useState(true);
    const [warningMessage, setWarningMessage] = useState('');

    const onEmailFieldChange = (e) => {
        setEmail(e.target.value);
        setIsInvalidateEmailFormat(!checkEmailValidation(e.target.value));
        if (e.target.value !== '' && !checkEmailValidation(e.target.value))
            setWarningMessage(
                ' This is not a valid email. Please check your email address.'
            );
        else setWarningMessage('');
    };

    const checkEmailValidation = (value) => {
        if (value === '') return false;

        return checkEmailFormat(value);
    };

    const onClickSendButton = () => {
        axiosUtil({
            url: `/auth/password`,
            method: 'POST',
            data: {
                user_name: email,
            },
        })
            .then((res) => {
                setIsSendCode((prev) => !prev);
            })
            .catch((err) => {
                setWarningMessage(' Your ID is incorrect. Please try again.');
            });
    };

    const EnterEmail = (
        <div className='box'>
            {isExpired ? linkExpiredTitle() : resetPasswordTitle()}
            <div className='field_wrapper'>
                <TopLabelInput
                    label={'User ID'}
                    targetId={'email'}
                    inputType={'text'}
                    placeholder={'Enter your email'}
                    onChangeHandler={onEmailFieldChange}
                    value={email}
                    inputStyle={{ width: '336px' }}
                />
                {warningMessage && (
                    <div className='warning_text'>{warningMessage}</div>
                )}
            </div>

            {/* Send Code Button */}
            <BasicButton
                className='main_button'
                content={'RESET PASSWORD'}
                onClick={onClickSendButton}
                styleProps={{
                    width: '336px',
                    height: '44px',
                }}
                disabled={isInvalidateEmailFormat}
            />
        </div>
    );

    return (
        <div className='ForgotPassword'>
            <div className='content_wrapper'>
                <div>
                    {isSendCode ? <ResetPassword email={email} /> : EnterEmail}
                </div>
                <Link
                    text='Return to Login Page'
                    type='exposed'
                    onClick={() => navigate('/signin')}
                />
            </div>
        </div>
    );
}

export default ForgotPassword;

function resetPasswordTitle() {
    return (
        <LogoTitleDescriptionForm
            title={'Reset Password'}
            logo={keyIllust}
            description={
                'Forgot your password? Please enter your email address. \n You will recieve a link to create a new password.'
            }
        />
    );
}

function linkExpiredTitle() {
    return (
        <LogoTitleDescriptionForm
            warning
            title={'Link Expired'}
            logo={expiredIllust}
            description={
                'Sorry, the link you used has expired. \n Please try again using the most recent password reset email \n in your inbox, or request a new link.'
            }
        />
    );
}
