import { useEffect, useRef, useState } from "react";

import { ReactComponent as CheckIcon } from "../../../assets/icons/checkIcon.svg";
import { ReactComponent as CheckedCheckBoxIcon } from "../../../assets/icons/checkedCheckBoxIcon.svg";
import { ReactComponent as NonCheckedCheckBoxIcon } from "../../../assets/icons/nonCheckedCheckBoxIcon.svg";

import styles from "./SelectDropdown.module.scss";

const ListItem = ({ onClickItem, name, idx, selected, type }) => {
  const textRef = useRef(null);
  const [isTooltip, setIsTooltip] = useState(false);

  useEffect(() => {
    const scrollWidth = textRef.current?.scrollWidth
      ? textRef.current?.scrollWidth
      : 0;
    const contentWidth = textRef.current?.offsetWidth
      ? textRef.current?.offsetWidth
      : 0;
    setIsTooltip(scrollWidth > contentWidth);
  }, []);
  const checkIcon = selected ? (
    <CheckedCheckBoxIcon className={styles.checkedIcon} />
  ) : (
    <NonCheckedCheckBoxIcon />
  );

  return (
    <button onClick={onClickItem(idx)} className={styles.selectListItem}>
      {type === "multi" && <div className={styles.iconBox}>{checkIcon}</div>}
      <p
        ref={textRef}
        className={`${styles.text} ${
          selected && type !== "multi" && styles.selected
        }`}
      >
        {name}
      </p>
      {isTooltip && <p className={styles.tooltip}>{name}</p>}
      {type === "single" && (
        <div className={styles.iconBox}>
          {selected && <CheckIcon className={styles.icon} />}
        </div>
      )}
    </button>
  );
};

export default ListItem;
