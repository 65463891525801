import styles from './NoRowsOverlay.module.scss';
import { ReactComponent as NoMonitoringData } from 'src/assets/illust/noMonitoringData.svg';

export default function NoRowsOverlay({ text = 'No Rows To Show' }) {
    return (
        <div className={styles.container}>
            <NoMonitoringData />
            <span>{text}</span>
        </div>
    );
}
