// CSS
import './TopLabelInput.scss';

import AsteriskIcon from 'src/assets/icons/asterisk';
import TextField from '../TextField/TextField';

function TopLabelInput({
    label,
    targetId,
    inputType = 'text',
    placeholder = '',
    value = '',
    onChangeHandler = undefined,
    onKeyDown = undefined,
    isRequired = false,
    inputStyle = {},
    labelStyle = {},

    disabled,
    helpMessage = '',
    errorMessage = '',
    autoFocus = false,
}) {
    const textInputStyle = {
        height: '40px',
        padding: '10px 12px',
        background: '#FFFFFF',
        ...inputStyle,
    };

    return (
        <div className='TopLabelInput'>
            <label
                htmlFor={targetId}
                className='input_header'
                style={labelStyle}
            >
                {label}
                <div className='asterisk'>{isRequired && <AsteriskIcon />}</div>
            </label>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                }}
            >
                <TextField
                    type={inputType}
                    labelName={label}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value}
                    helpMessage={helpMessage}
                    errorMessage={errorMessage}
                    onChangeHandler={onChangeHandler}
                    onKeyDownHandler={onKeyDown}
                    styleProps={textInputStyle}
                    autoFocus
                />
            </div>
        </div>
    );
}

export default TopLabelInput;
