import s from './Account.module.scss';

import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userContext } from 'src/router/RouterGuard';
import authenticateUser from 'src/utils/authenticateUser';

import SimpleModal from 'src/components/molecules/SimpleModal/SimpleModal';
import TextField from 'src/components/atoms/TextField/TextField';
import AccountCard from './AccountCard/AccountCard';

function Account() {
    const navigate = useNavigate();

    const user = useContext(userContext);

    const [accountData, setAccountData] = useState({});
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordComfirmModalOpen, setIsPasswordComfirmModalOpen] =
        useState(true);

    const handleCommitButton = async () => {
        const { success: isAuthenticate } = await authenticateUser(
            user.user_name,
            password
        );

        if (isAuthenticate) {
            getAccount(user);
            setIsPasswordComfirmModalOpen(false);
        } else {
            setPasswordError('Your password is incorrect. Please try again.');
        }
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
            handleCommitButton();
        }
    };

    const getAccount = (user) => {
        const initAccount = JSONtoJS(user);
        setAccountData(initAccount);
    };

    const JSONtoJS = (data) => {
        return {
            firstName: data.first_name,
            lastName: data.last_name,
            userName: data.user_name,
            companyName: data.company_name,
            department: data.department,
            jobTitle: data.job_title,
            country: data.country,
            companyNumber: data.company_number,
            IsMPUser: data.is_mp_user,
            userIdentifier: data.user_identifier,
            userAWSID: data.user_aws_id,
            productCode: data.product_code,
            supportPlan: data.support_plan,
            addressLineOne: data.address_line_one,
            addressLineTwo: data.address_line_two,
            city: data.city,
            postalCode: data.postal_code,
            phoneNumber: data.phone_number,
        };
    };

    const passwordComfirmModalContent = (
        <div className={s.passwordComfirmModalContent}>
            <p>To proceed with email change please confirm your password.</p>
            <TextField
                type='password'
                placeholder='Password'
                value={password}
                onChangeHandler={(e) => {
                    setPassword(e.target.value);
                }}
                onKeyDownHandler={handleEnter}
                errorMessage={passwordError}
            />
        </div>
    );

    return (
        <div className={s.account}>
            {isPasswordComfirmModalOpen ? (
                <SimpleModal
                    title='Password Confirm'
                    children={passwordComfirmModalContent}
                    onClickClose={() => navigate(-1)}
                    onClickOK={handleCommitButton}
                    okButtonDisabled={!password}
                />
            ) : (
                <AccountCard initData={accountData} />
            )}
        </div>
    );
}

export default Account;
