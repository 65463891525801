import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { userContext } from 'src/router/RouterGuard';

// CSS
import './UserInfo.css';

// Components
import Profile from 'src/components/atoms/Profile/Profile';
import { ReactComponent as LogoutIcon } from 'src/assets/icons/logout.svg';
import DeleteAccountModal from '../DeleteAccountModal/DeleteAccountModal';
import { clearLocalStorage } from 'src/utils/clearLocalStorage';

function UserInfo({ isClicked }) {
    const navigate = useNavigate();
    const user = useContext(userContext);

    const [isDeleteAccoutModalOpen, setIsDeleteAccountModalOpen] =
        useState(true);

    const [userInfo, setUserInfo] = useState({
        name: '',
        email: '',
        admin: false,
    });

    const handleLogOut = () => {
        clearLocalStorage();
        navigate('/signin');
    };

    useEffect(() => {
        if (user) {
            if (user) {
                setUserInfo({
                    name: user.first_name + ' ' + user.last_name,
                    email: user.user_name,
                    admin: false,
                });
            }
        }
    }, [user]);

    return (
        <div className={`UserInfo ${isClicked ? 'show' : ''}`}>
            {isDeleteAccoutModalOpen && (
                <DeleteAccountModal
                    email={userInfo.email}
                    setIsDeleteAccountModalOpen={setIsDeleteAccountModalOpen}
                />
            )}
            <div className='userInfo_wrapper'>
                <div className='name_content'>
                    <div className='name'>
                        <Profile
                            size='large'
                            name={userInfo.name}
                            variation={1}
                        />
                        <span>{userInfo.name}</span>
                    </div>
                    {userInfo.admin && <span className='admin'>관리자</span>}
                </div>
                <span className='email'>{userInfo.email}</span>
            </div>
            <div className='dropdown_wrapper'>
                {/* <div
                    className='dropdown'
                    onClick={() => {
                        setIsDeleteAccountModalOpen(true);
                    }}
                >
                    <AccountDeleteIcon />
                    <div>Delete account</div>
                </div> */}
                <div
                    className='dropdown'
                    onClick={() => {
                        handleLogOut();
                    }}
                >
                    <LogoutIcon />
                    <div>Logout</div>
                </div>
            </div>
        </div>
    );
}

export default UserInfo;
