import s from './CommitButton.module.scss';

function CommitButton({
    content = 'button',
    onClick = undefined,
    level = 'primary', // primary | secondary
    size = 'md', // sm | md | lg
    disabled,
    styleProps = {},
}) {
    return (
        <button
            className={`${s.button} ${s[level]} ${s[size]}`}
            onClick={onClick}
            disabled={disabled}
            style={styleProps}
        >
            {content}
        </button>
    );
}

export default CommitButton;
