import styles from './BasicButton.module.scss';

export default function BasicButton({
    content = 'button',
    onClick = undefined,
    disabled = false,
    level = 'primary', // primary | secondary | delete
    type = 'filled', // filled | outline | ghost
    size = 'md', // sm | md | lg
    startIcon,
    styleProps = {},
}) {
    return (
        <div>
            <button
                className={`${styles.button} ${styles[level]} ${styles[type]} ${styles[size]}`}
                disabled={disabled}
                onClick={onClick}
                style={{ ...styleProps }}
            >
                {startIcon && <div className={styles.icon}>{startIcon}</div>}
                <span>{content}</span>
            </button>
        </div>
    );
}
