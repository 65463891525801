import s from './Billing.module.scss';
import axiosUtil from 'src/utils/axiosUtil';

import { useContext, useEffect, useRef, useState } from 'react';
import { userContext } from 'src/router/RouterGuard';

import Label from 'src/components/atoms/Label/Label';
import TextField from 'src/components/atoms/TextField/TextField';
import FilterChip from 'src/components/atoms/FilterChip/FilterChip';
import DeploymentBillingCard from './DeploymentBillingCard/DeploymentBillingCard';
import { getUSCurrencyFormat } from 'src/utils/convert';

function Billing() {
    const today = new Date();

    const user = useContext(userContext);
    const billingRef = useRef(null);

    const [billingData, setBillingData] = useState();
    const [deploymentBilling, setDeploymentBilling] = useState([]);

    const getBilling = async () => {
        await axiosUtil
            .get(`/billing?user_id=${user.user_name}`)
            .then((res) => {
                setBillingData(res.data);
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    // toISOString()은 UTC를 기준으로 반환되기 때문에, Local Time Zone에 맞추기 위한 조정 작업
    const adjustTimezoneOffset = (date) => {
        const offset = today.getTimezoneOffset() * 60000;
        return new Date(date - offset).toISOString();
    };

    const getInvoiceDate = () => {
        if (deploymentBilling.length === 0) {
            return '-';
        }
        // 항상 다음달 1일
        const invoiceDate = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            1
        ); // 2024-04-01

        const year = invoiceDate.getFullYear();
        const month = ('0' + (invoiceDate.getMonth() + 1)).slice(-2); // (01, 02 ... 12)
        const day = ('0' + invoiceDate.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    };

    const getPreInvoiceDate = () => {
        let preInvoiceDate = new Date(getInvoiceDate());
        preInvoiceDate.setMonth(new Date(getInvoiceDate()).getMonth() - 1);
        return adjustTimezoneOffset(preInvoiceDate).slice(0, 10); // 2024-03-01
    };

    const getFirstCreatedDate = () => {
        const serviceDates = [];
        deploymentBilling.forEach((billing) =>
            billing.service.forEach((service) =>
                serviceDates.push(service.created_time.slice(0, 10))
            )
        );
        return serviceDates.sort()[0];
    };

    const getBillingPeriod = () => {
        if (deploymentBilling.length === 0) {
            return '-';
        }

        const from = getFromPeriod();
        const to = getToPeriod();

        return `${from} ~ ${to}`;
    };

    const getFromPeriod = () => {
        const preInvoiceDate = getPreInvoiceDate();
        const firstCreatedDate = getFirstCreatedDate();
        const currentInvoiceDate = getInvoiceDate();

        // 최초 Billing 시작일자
        if (
            preInvoiceDate <= firstCreatedDate &&
            firstCreatedDate < currentInvoiceDate
        ) {
            return firstCreatedDate;
        }

        // 첫번째 이후 Billing 시작일자
        return preInvoiceDate;
    };

    const getToPeriod = () => {
        return adjustTimezoneOffset(today).slice(0, 10);
    };

    const onClickItem = (target) => {
        setDeploymentBilling((prev) =>
            prev.map((deployment, index) => {
                const selected = deployment.selected;
                if (target === index) {
                    return { ...deployment, selected: !selected };
                }
                return { ...deployment, selected: false };
            })
        );
        scrollToSelectedBilling(target);
    };

    const scrollToSelectedBilling = (index) => {
        // index + 1인 이유는 'Total Amount' 다음부터 <DeploymentBillingCard/> 컴포넌트이기 때문
        const element = billingRef.current.children[index + 1];
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    useEffect(() => {
        if (user !== null) {
            getBilling();
        }
    }, [user]);

    useEffect(() => {
        if (billingData && billingData.deployment_billing) {
            setDeploymentBilling(
                billingData.deployment_billing.map((deployment, index) => ({
                    ...deployment,
                    selected: index === 0 ? true : false,
                }))
            );
        }
    }, [billingData]);

    return (
        billingData && (
            <div className={s.billing} ref={billingRef}>
                <div className={s.billingCard}>
                    <div className={s.container}>
                        <div className={s.header}>
                            <h2>Total Amount</h2>
                        </div>

                        <div className={s.content}>
                            <span className={s.total_amount}>
                                {getUSCurrencyFormat(billingData.user_total)}
                            </span>

                            <div className={s.date_container}>
                                <div className={s.vertical_wrapper}>
                                    <div className={s.label}>
                                        <Label name='Invoice Date' />
                                    </div>
                                    <TextField
                                        mode='read'
                                        value={getInvoiceDate()}
                                        styleProps={{ width: '320px' }}
                                    />
                                </div>

                                <div className={s.vertical_wrapper}>
                                    <div className={s.label}>
                                        <Label name='Billing Period' />
                                    </div>
                                    <TextField
                                        mode='read'
                                        value={getBillingPeriod()}
                                        styleProps={{ width: '320px' }}
                                    />
                                </div>

                                <div className={s.vertical_wrapper}>
                                    <div className={s.label}>
                                        <Label name='Support Plan' />
                                    </div>
                                    <TextField
                                        mode='read'
                                        value={user.support_plan}
                                        styleProps={{
                                            width: '100%',
                                            textTransform: 'capitalize',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.container}>
                        <div className={s.header}>
                            <h3>
                                Total Number of Deployment
                                <span>{deploymentBilling.length}</span>
                            </h3>
                        </div>
                        {deploymentBilling.length !== 0 && (
                            <div className={s.content}>
                                <div className={s.chip_container}>
                                    {deploymentBilling.map(
                                        (deployment, index) => (
                                            <FilterChip
                                                key={index}
                                                index={index}
                                                text={
                                                    deployment.deployment_name
                                                }
                                                selected={deployment.selected}
                                                onClick={onClickItem}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {deploymentBilling?.map((item, index) => (
                    <DeploymentBillingCard
                        key={item.deployment_name}
                        index={index}
                        deploymentBilling={item}
                        onClickItem={onClickItem}
                    />
                ))}
            </div>
        )
    );
}

export default Billing;
