// CSS
import './LeftLabelInput.scss';

// Assets
import AsteriskIcon from 'src/assets/icons/asterisk';

const LeftLabelInput = ({
    label,
    targetId,
    inputType = 'text',
    placeholder = '',
    value = '',
    onChangeHandler = () => {},
    onKeyDown = () => {},
    isRequired = false,
    ...props
}) => {
    return (
        <div className='LeftLabelInput'>
            <label htmlFor={targetId} className='input_header'>
                {label}
                <div className='asterisk'>{isRequired && <AsteriskIcon />}</div>
            </label>
            <div>
                <input
                    type={inputType}
                    id={targetId}
                    className='input_field'
                    placeholder={placeholder}
                    onChange={onChangeHandler}
                    onKeyDown={onKeyDown}
                    value={value}
                    style={props}
                />
            </div>
        </div>
    );
};

export default LeftLabelInput;
