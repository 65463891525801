// CSS
import { useNavigate } from 'react-router-dom';
import './SignUpWelcomePage.scss';

// Assets
import softWelcomeImage from 'src/assets/illust/softWelcome2.gif';

// Components
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';

function SignUpWelcomePage() {
    const navagite = useNavigate();
    return (
        <div className='SignUpWelcomePage'>
            <div className='page_content'>
                <img src={softWelcomeImage} alt='welcome' width={320} />
                <div className='title'>Thanks for signing up!</div>
                <div className='explain_text'>
                    <div>
                        Your account has been successfully activated. Please use
                        the link below to login to your account.
                    </div>
                </div>
                <BasicButton
                    content={'Login to OpenFrame Refactor'}
                    onClick={() => navagite('/')}
                    styleProps={{
                        width: '336px',
                        height: '44px',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '20px',
                        letterSpacing: '-0.002em',
                        margin: '24px 0px',
                    }}
                />
                <div className='bottom_text'>
                    Thank you for choosing OpenFrame Refactor
                </div>
            </div>
        </div>
    );
}

export default SignUpWelcomePage;
