import { useEffect, useRef, useState } from "react";

export default function useClickAway() {
  const [isClicked, setIsClicked] = useState(false);
  const clickRef = useRef(null);

  const handleClickAway = (e) => {
    if (!clickRef.current?.contains(e.target)) {
      setIsClicked(false);
    }
  };

  const onToggle = () => {
    setIsClicked((prev) => !prev);
  };

  useEffect(() => {
    if (isClicked) {
      document.addEventListener("click", handleClickAway);
    } else {
      document.removeEventListener("click", handleClickAway);
    }
    return () => {
      document.removeEventListener("click", handleClickAway);
    };
  }, [isClicked]);

  return { isClicked, clickRef, onToggle };
}
