import s from './DeploymentBillingCard.module.scss';

import { useCallback, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { getUSCurrencyFormat } from 'src/utils/convert';

import Label from 'src/components/atoms/Label/Label';
import TextField from 'src/components/atoms/TextField/TextField';
import AccordianButton from 'src/components/atoms/AccordianButton/AccordianButton';
import FreeTrial from 'src/components/atoms/FreeTrial/FreeTrial';

const getRunningTimeFormat = (params) => {
    let created = new Date(params.value);
    let today = new Date();

    let diff = today - created;

    let seconds = Math.floor((diff / 1000) % 60);
    diff = diff / 1000;

    let minutes = Math.floor((diff / 60) % 60);
    diff = diff / 60;

    let hours = Math.floor(diff / 60);

    return `${hours}:${minutes}:${seconds}`;
};

function DeploymentBillingCard({ index, deploymentBilling, onClickItem }) {
    const gridRef = useRef();
    const [gridHeight, setGridHeight] = useState(0);

    const [rowData] = useState(deploymentBilling.service);
    const [colDefs] = useState([
        { headerName: 'Service', field: 'service_name', flex: 1 },
        { headerName: 'Details', field: 'tier' },
        {
            headerName: 'Service Run Time',
            field: 'created_time',
            valueFormatter: getRunningTimeFormat,
        },
        {
            headerName: 'Amount',
            field: 'amount',
            valueFormatter: (params) => getUSCurrencyFormat(params.value),
        },
    ]);

    const serviceName = deploymentBilling.service[0].service_name;
    const tier = deploymentBilling.service[0].tier;
    const openStyle = deploymentBilling.selected ? 'on' : 'off';

    const onGridReady = () => {
        adjustGridHeight();
    };

    const adjustGridHeight = () => {
        const sizeForCurrentTheme =
            gridRef.current?.api.getSizesForCurrentTheme();

        const buffer = 1; // Prevent Table Scroll

        const maxGridHeight =
            sizeForCurrentTheme.headerHeight +
            sizeForCurrentTheme.rowHeight * rowData.length +
            buffer;

        setFixedHeight(maxGridHeight);
    };

    const setFixedHeight = useCallback((maxGridHeight) => {
        gridRef.current.api.setGridOption('domLayout', 'normal');
        setGridHeight(maxGridHeight);
    }, []);

    return (
        <div className={`${s.deploymentBillingCard} ${s[openStyle]}`}>
            <div className={s.summary}>
                <div className={s.container}>
                    <div className={s.header}>
                        <div className={s.title}>
                            <h2>{deploymentBilling.deployment_name}</h2>
                            <AccordianButton
                                onClick={() => onClickItem(index)}
                                open={deploymentBilling.selected}
                            />
                        </div>
                        <p>{serviceName}</p>
                    </div>

                    <div className={s.content}>
                        <div className={s.amount_container}>
                            <div className={s.vertical_wrapper}>
                                <div className={s.label}>
                                    <Label name='Amount' />
                                    {deploymentBilling.is_free && <FreeTrial />}
                                </div>
                                <TextField
                                    mode='read'
                                    value={getUSCurrencyFormat(
                                        deploymentBilling.total_billing
                                    )}
                                    styleProps={{
                                        width: '320px',
                                        color: '#6137DF',
                                        fontSize: '18px',
                                        lienHeight: '28px',
                                        letterSpacing: '-0.036px',
                                    }}
                                />
                            </div>

                            <div className={s.vertical_wrapper}>
                                <div className={s.label}>
                                    <Label name='Tier' />
                                </div>
                                <TextField
                                    mode='read'
                                    value={tier}
                                    styleProps={{
                                        width: '100%',
                                        fontSize: '18px',
                                        lienHeight: '28px',
                                        letterSpacing: '-0.036px',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.details_wrapper}>
                <div className={s.container}>
                    <div className={s.header}>
                        <h3>Billing Details</h3>
                    </div>

                    <div
                        className='ag-theme-dui'
                        style={{ height: gridHeight }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            onGridReady={onGridReady}
                            rowData={rowData}
                            columnDefs={colDefs}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeploymentBillingCard;
