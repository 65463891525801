import s from "./IconButton.module.scss";

export default function IconButton({
  icon = "",
  onClick = undefined,
  disabled = false,
  level = "primary", // primary | secondary | delete
  type = "filled", // filled | outline | ghost | outlineCirlce | ghostNoBg
  size = "md", // sm | md | lg
  styleProps = {},
  theme = "light", // light | dark
}) {
  return (
    <div>
      <button
        className={`${s.button} ${s[level]} ${s[type]} ${s[size]} ${s[theme]}`}
        disabled={disabled}
        onClick={onClick}
        style={{ ...styleProps }}
      >
        <div className={s.icon}>{icon}</div>
      </button>
    </div>
  );
}
