import s from './Link.module.scss';
import { ReactComponent as KeyboardArrowRightIcon } from 'src/assets/icons/keyboard_arrow_right.svg';

function Link({
    type = 'hidden', // hidden | hiddenArrow | exposed
    text = 'Link',
    onClick,
    styleProps,
}) {
    return (
        <a
            className={`${s.link} ${s[type]}`}
            onClick={onClick}
            style={{ ...styleProps }}
        >
            {text}
            {type === 'hiddenArrow' && (
                <KeyboardArrowRightIcon
                    className={s.icon}
                    style={styleProps ? { color: 'inherit' } : {}}
                />
            )}
        </a>
    );
}

export default Link;
