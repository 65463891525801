import s from './AccountCard.module.scss';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as PasswordIcon } from 'src/assets/icons/password.svg';

import axiosUtil from 'src/utils/axiosUtil';
import { encryptObject } from 'src/utils/encryptUtil';

import { useEffect, useState } from 'react';
import { countries } from 'countries-list';

import Label from 'src/components/atoms/Label/Label';
import TextField from 'src/components/atoms/TextField/TextField';
import BaseSelect from 'src/components/atoms/BaseSelect/BaseSelect';
import CommitBar from 'src/components/molecules/CommitBar/CommitBar';
import CommitButton from 'src/components/atoms/CommitButton/CommitButton';
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import SimpleModal from 'src/components/molecules/SimpleModal/SimpleModal';
import DeleteAccountModal from 'src/components/molecules/DeleteAccountModal/DeleteAccountModal';
import AccountChangePassword from '../AccountChangePassword/AccountChangePassword';

function AccountCard({ initData }) {
    // Account Edit
    const [isEdit, setIsEdit] = useState(false);
    const [isAccountFormValid, setIsAccountFormValid] = useState(false);
    const [initAccountInfo, setInitAccountInfo] = useState(initData);
    const [accountInfo, setAccountInfo] = useState({});
    const [countryArray, setCountryArray] = useState(() =>
        Object.values(countries).map((el, idx) => {
            return {
                name: el.name,
                selected: false,
            };
        })
    );

    // Modal
    const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
        useState(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
        useState(false);
    const [isLeaveSiteModalOpen, setIsLeaveSiteModalOpen] = useState(false);

    const initializeForm = (initAccountInfo) => {
        setAccountInfo(initAccountInfo);
        setCountryArray((prev) =>
            prev.map((country) => ({
                ...country,
                selected: country.name === initAccountInfo.country,
            }))
        );
    };

    const updateAccount = async () => {
        const updateData = await encryptObject({
            company_name: accountInfo.companyName,
            department: accountInfo.department,
            job_title: accountInfo.jobTitle,
            country: accountInfo.country,
            company_number: accountInfo.companyNumber,
            address_line_one: accountInfo.addressLineOne,
            address_line_two: accountInfo.addressLineTwo,
            city: accountInfo.city,
            postal_code: accountInfo.postalCode,
            phone_number: accountInfo.phoneNumber,
        });

        await axiosUtil({
            url: `/users/${initData.userName}`,
            method: 'PATCH',
            data: updateData,
        })
            .then((res) => {
                setInitAccountInfo(accountInfo);
                setIsEdit(false);
            })
            .catch((error) => {
                alert('Account Update Error');
            });
    };

    const compareAccountObject = () => {
        return JSON.stringify(initAccountInfo) === JSON.stringify(accountInfo);
    };

    const handleCancelClick = () => {
        const isNotChanged = compareAccountObject();
        if (isNotChanged) {
            setIsEdit(false);
        } else {
            setIsLeaveSiteModalOpen(true);
        }
    };

    const handleLeaveClick = () => {
        setIsLeaveSiteModalOpen(false);
        setIsEdit(false);
        initializeForm(initAccountInfo);
    };

    useEffect(() => {
        setInitAccountInfo(initData);
    }, [initData]);

    useEffect(() => {
        initializeForm(initAccountInfo);
    }, [initAccountInfo]);

    useEffect(() => {
        const formArray = ['jobTitle', 'country'];
        const isAllFieldValid = formArray.every(
            (key) => accountInfo[key] !== ''
        );
        setIsAccountFormValid(isAllFieldValid);
    }, [accountInfo]);

    const leaveSiteModalContent = (
        <div className={s.leaveSiteModalContent}>
            <p>Chanes you made may not be saved.</p>
        </div>
    );

    return (
        <div className={s.accountCard}>
            <div className={s.card}>
                <div className={s.info_container}>
                    <div className={s.header}>
                        <h2>General Information</h2>
                    </div>

                    <div className={s.content}>
                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'First Name'} />
                            </div>

                            <TextField
                                mode='read'
                                labelName={'First Name'}
                                value={accountInfo.firstName}
                                placeholder='First Name'
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        firstName: e.target.value,
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Last Name'} />
                            </div>

                            <TextField
                                mode='read'
                                labelName={'Last Name'}
                                value={accountInfo.lastName}
                                placeholder='Last Name'
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        lastName: e.target.value,
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Email'} />
                            </div>

                            <TextField
                                mode='read'
                                labelName={'Email'}
                                value={accountInfo.userName}
                                placeholder='Email'
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        userName: e.target.value,
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>
                    </div>
                </div>

                <hr />

                <div className={s.info_container}>
                    <div className={s.content}>
                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Password'} />
                            </div>
                            <div>
                                <BasicButton
                                    level='secondary'
                                    type='outline'
                                    content='Change Password'
                                    startIcon={<PasswordIcon />}
                                    onClick={() =>
                                        setIsChangePasswordModalOpen(true)
                                    }
                                />
                                <span className={s.help}>
                                    You will be logged out from all devices once
                                    you change your password.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className={s.info_container}>
                    <div className={s.header}>
                        <h2>Company Information</h2>
                        {!isEdit && (
                            <BasicButton
                                level='secondary'
                                type='outline'
                                content='Edit'
                                startIcon={<EditIcon />}
                                onClick={() => setIsEdit(true)}
                            />
                        )}
                    </div>

                    <div className={s.content}>
                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Company Name'} />
                            </div>

                            <TextField
                                mode='read'
                                labelName={'Company Name'}
                                value={accountInfo.companyName}
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        companyName: e.target.value,
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Department'} />
                            </div>

                            <TextField
                                mode={isEdit ? 'edit' : 'read'}
                                labelName={'Department'}
                                value={accountInfo.department}
                                placeholder={'Department'}
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        department: e.target.value,
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Job Title'} isRequired={isEdit} />
                            </div>

                            <TextField
                                mode={isEdit ? 'edit' : 'read'}
                                labelName={'Job Title'}
                                value={accountInfo.jobTitle}
                                placeholder={'Job Title'}
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        jobTitle: e.target.value,
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Address Line 1'} />
                            </div>

                            <TextField
                                mode={isEdit ? 'edit' : 'read'}
                                labelName={'Address Line 1'}
                                value={accountInfo.addressLineOne}
                                placeholder='Address Line 1'
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        addressLineOne: e.target.value,
                                    }));
                                }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Address Line 2'} />
                            </div>

                            <TextField
                                mode={isEdit ? 'edit' : 'read'}
                                labelName={'Address Line 2'}
                                value={accountInfo.addressLineTwo}
                                placeholder='Address Line 2'
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        addressLineTwo: e.target.value,
                                    }));
                                }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'City'} />
                            </div>

                            <TextField
                                mode={isEdit ? 'edit' : 'read'}
                                labelName={'City'}
                                value={accountInfo.city}
                                placeholder='City'
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        city: e.target.value,
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Postal Code'} />
                            </div>

                            <TextField
                                mode={isEdit ? 'edit' : 'read'}
                                labelName={'Postal Code'}
                                value={accountInfo.postalCode}
                                placeholder='Postal Code'
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        postalCode: e.target.value.replace(
                                            /[^0-9]/g,
                                            ''
                                        ),
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Country'} isRequired={isEdit} />
                            </div>

                            {!isEdit ? (
                                <TextField
                                    mode={'read'}
                                    labelName={'Country'}
                                    value={accountInfo.country}
                                    placeholder='Country'
                                    styleProps={{ width: '320px' }}
                                />
                            ) : (
                                <BaseSelect
                                    data={countryArray}
                                    value={accountInfo.country}
                                    onChange={(name) => {
                                        setAccountInfo((prev) => ({
                                            ...prev,
                                            country: name,
                                        }));
                                    }}
                                />
                            )}
                        </div>

                        <div className={s.horizontal_wrapper}>
                            <div className={s.label}>
                                <Label name={'Phone Number'} />
                            </div>

                            <TextField
                                mode={isEdit ? 'edit' : 'read'}
                                labelName={'Phone Number'}
                                value={accountInfo.phoneNumber}
                                placeholder='Phone Number'
                                onChangeHandler={(e) => {
                                    setAccountInfo((prev) => ({
                                        ...prev,
                                        phoneNumber: e.target.value.replace(
                                            /[^0-9-]/g,
                                            ''
                                        ),
                                    }));
                                }}
                                styleProps={{ width: '320px' }}
                            />
                        </div>
                    </div>

                    {!isEdit && (
                        <BasicButton
                            level='secondary'
                            type='outline'
                            content='Delete Account'
                            onClick={() => setIsDeleteAccountModalOpen(true)}
                        />
                    )}
                </div>
            </div>

            {isEdit && (
                <CommitBar>
                    <div className={s.commit_button_container}>
                        <CommitButton
                            content='Save'
                            onClick={updateAccount}
                            disabled={!isAccountFormValid}
                            styleProps={{ minWidth: '128px' }}
                        />
                        <CommitButton
                            content='Cancel'
                            level='secondary'
                            onClick={handleCancelClick}
                        />
                    </div>
                </CommitBar>
            )}

            {isChangePasswordModalOpen && (
                <AccountChangePassword
                    setIsChangePasswordModalOpen={setIsChangePasswordModalOpen}
                />
            )}

            {isDeleteAccountModalOpen && (
                <DeleteAccountModal
                    email={accountInfo.userName}
                    setIsDeleteAccountModalOpen={setIsDeleteAccountModalOpen}
                />
            )}

            {isLeaveSiteModalOpen && (
                <SimpleModal
                    title='Leave site?'
                    children={leaveSiteModalContent}
                    onClickClose={() => setIsLeaveSiteModalOpen(false)}
                    onClickOK={handleLeaveClick}
                    cancelButtonText='Cancel'
                    okButtonText='Leave'
                />
            )}
        </div>
    );
}

export default AccountCard;
