import s from './SignUpConfirmCodeForm.module.scss';

// Hooks
import { useRef, useState } from 'react';
import useShake from 'src/hooks/useShake';
import axiosUtil from 'src/utils/axiosUtil';

// Components
import TextField from 'src/components/atoms/TextField/TextField';
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import Link from 'src/components/atoms/Link/Link';

function SignUpConfirmCodeForm({ email, setIsConfirmationCompleted }) {
    const [code, setCode] = useState(new Array(6).fill(''));
    const [isCodeCorrect, setIsCodeCorrect] = useState(true);
    const { shake, shakeAlert } = useShake();

    // Confirm Code 자동 포커스를 위한 ref
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();
    const inputRef5 = useRef();

    const inputRefArray = [
        undefined,
        inputRef1,
        inputRef2,
        inputRef3,
        inputRef4,
        inputRef5,
    ];

    const onCodeFieldChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, '');

        setCode((prev) => {
            let code = [...prev];
            code[index] = value;
            return code;
        });

        if (value) {
            handleMoveFocus(index);
        }
    };

    const handleMoveFocus = (inputOrderNum) => {
        const nextInputRef = inputRefArray[inputOrderNum + 1];
        nextInputRef?.current.focus();
    };

    const handleClickVerifyCode = async () => {
        const confirmData = {
            confirm_code: code.join(''),
            user_name: email,
        };

        await axiosUtil({
            url: `/auth/confirmation`,
            method: 'POST',
            data: confirmData,
        })
            .then((res) => {
                setIsCodeCorrect(true);
                setIsConfirmationCompleted(true);
            })
            .catch((error) => {
                setIsCodeCorrect(false);
                shakeAlert();
            });
    };

    const handleClickResend = async () => {
        await axiosUtil({
            url: `/auth/resend/code`,
            method: 'POST',
            data: {
                user_name: email,
            },
        })
            .then((res) => {
                alert('Success Resend Code.');
            })
            .catch((error) => {
                alert('Failed to resend Code.');
            });
    };

    return (
        <div className={s.SignUpConfirmCodeForm}>
            <div className={s.field_wrapper}>
                <div className={s.confirm_code_wrapper}>
                    {inputRefArray.map((inputRef, index) => (
                        <TextField
                            value={code[index]}
                            ref={inputRef}
                            maxLength={1}
                            onChangeHandler={(e) => onCodeFieldChange(e, index)}
                            styleProps={textInputStyle}
                            autoFocus={index === 0}
                        />
                    ))}
                </div>
                {!isCodeCorrect && (
                    <div className={`${s.warning_text} ${shake && s.shake}`}>
                        Invalid verification code provided, please try again
                        <br />
                        or click the Resend Code
                    </div>
                )}
            </div>

            <BasicButton
                content={'Verify Code'}
                onClick={handleClickVerifyCode}
                disabled={code.some((value) => value === '')}
                styleProps={{
                    width: '336px',
                    height: '44px',
                }}
            />

            <div className={s.mail_yet}>
                <span>Haven't you received the mail yet?</span>
                <Link
                    text='Resend Code'
                    type='exposed'
                    onClick={handleClickResend}
                />
            </div>
        </div>
    );
}

export default SignUpConfirmCodeForm;

const textInputStyle = {
    width: '40px',
    height: '40px',
    padding: '10px 12px',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '-0.002em',
    textAlign: 'center',
};
