// CSS
import styles from './Event.module.scss';

// Libraries
import axiosUtil from 'src/utils/axiosUtil';

// Assets
import { ReactComponent as EventInfoIcon } from 'src/assets/icons/eventInfoIcon.svg';
import { ReactComponent as EventWarningIcon } from 'src/assets/icons/eventWarningIcon.svg';
import { ReactComponent as EventCriticalIcon } from 'src/assets/icons/eventCriticalIcon.svg';
import { ReactComponent as EventSevereIcon } from 'src/assets/icons/eventSevereIcon.svg';

// Hooks
import { useState, useContext, useEffect } from 'react';

import { userContext } from 'src/router/RouterGuard';

// Utils
import { convertTimestamp, convertStatus } from 'src/utils/convert';

// Components
import Loading from 'src/components/organisms/Loading/Loading';
import { AgGridReact } from 'ag-grid-react';
import RefreshButton from 'src/components/atoms/RefreshButton/RefreshButton';
import NoRowsOverlay from 'src/components/atoms/NoRowsOverlay/NoRowsOverlay';
import EventSidePanel from './EventSidePanel/EventSidePanel';

export default function Event() {
    const user = useContext(userContext);

    const [events, setEvents] = useState();
    const [clickedEvent, setClickedEvent] = useState();
    const [rowData, setRowData] = useState([]);

    const eventIcons = {
        info: <EventInfoIcon />,
        warning: <EventWarningIcon />,
        critical: <EventCriticalIcon />,
        severe: <EventSevereIcon />,
    };

    useEffect(() => {
        if (user !== null) {
            getEvents();
        }
    }, [user]);

    const getEvents = async () => {
        const eventData = await axiosUtil
            .get(`/events?user_id=${user.user_name}`)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                alert(error);
            });
        setEvents(eventData ?? []);
    };

    useEffect(() => {
        if (events && events.length > 0) {
            setRowData(events);
        }
    }, [events]);

    const onClickRefresh = () => {
        getEvents();
    };

    const [colDefs] = useState([
        {
            field: 'event_level',
            headerName: '',
            cellRenderer: (param) => {
                const icon = eventIcons[param.value];
                return icon;
            },
            maxWidth: 40,
            cellStyle: {
                height: '100%',
                display: 'flex ',
                'justify-content': 'center',
                'align-items': 'center ',
            },
        }, // severe, critical, warning, info
        {
            field: 'event_object',
            headerName: 'Object',
        }, //system, console, deploymnet, service...
        { field: 'object_name', headerName: 'Object Name' },

        {
            field: 'event_name',
            headerName: 'Event Name',
            cellRenderer: (param) => {
                const state = convertStatus(param.value) ?? '-';
                return state;
            },
        },
        // requested, created, deleted, started, completed
        { field: 'service_model', headerName: 'Service Model' },
        // { field: 'details' },
        // { field: 'suggestion' },
        {
            field: 'time_stamp',
            headerName: 'Time',
            cellRenderer: (param) => {
                return convertTimestamp(param.value);
            },
        },
        // { field: 'checked' }, // true, false (유저 확인 여부)
    ]);

    const autoSizeStrategy = {
        type: 'fitGridWidth',
    };

    const onRowClick = (param) => {
        const rowData = param.data;

        setClickedEvent({
            ...rowData,
            icon: eventIcons[rowData.event_level],
            event_name: convertStatus(rowData.event_name),
            time: convertTimestamp(rowData.time_stamp),
        });
    };

    const closeSidePanel = () => {
        setClickedEvent();
    };

    return (
        <div className={`${styles.Event} ${!events && styles.loading}`}>
            {!events ? (
                <Loading />
            ) : (
                <>
                    <div className={styles.card}>
                        <div className={styles.title_wrapper}>
                            <span className={styles.title}>Event List</span>
                            <div
                                className={styles.button}
                                onClick={onClickRefresh}
                            >
                                <RefreshButton size='18px' />
                            </div>
                        </div>

                        <div className={styles.grid_container}>
                            <div
                                className='ag-theme-dui'
                                style={{ height: 500 }}
                            >
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={colDefs}
                                    noRowsOverlayComponent={
                                        noRowsOverlayComponent
                                    }
                                    onRowClicked={onRowClick}
                                    rowStyle={{ cursor: 'pointer' }}
                                    autoSizeStrategy={autoSizeStrategy}
                                />
                            </div>
                        </div>
                    </div>
                    {clickedEvent && (
                        <EventSidePanel
                            clickedEvent={clickedEvent}
                            onClickClose={closeSidePanel}
                        />
                    )}
                </>
            )}
        </div>
    );
}

const noRowsOverlayComponent = () => {
    return <NoRowsOverlay text='No Event To Show' />;
};
