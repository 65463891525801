import { useState } from 'react';

// 경고 메시지를 표시하고 흔들림 효과를 주는 훅
function useShake() {
    const [shake, setShake] = useState(false);

    const shakeAlert = () => {
        setShake(true);
        setTimeout(() => {
            setShake(false);
        }, 500);
    };

    return { shake, shakeAlert };
}

export default useShake;
