import { forwardRef } from "react";

import styles from "./SelectDropdown.module.scss";

import ListItem from "./ListItem";

// interface Props {
//   items: { name: string; selected: boolean }[]
//   onClickItem: (idx: number) => () => void
//   type?: 'multi' | 'single'
// }

const SelectDropdown = forwardRef(
  ({ items, onClickItem, type = "single" }, ref) => {
    return (
      <div className={styles.selectListContainer} ref={ref}>
        {items.map(({ name, selected }, idx) => (
          <ListItem
            key={`${name}-${idx}`}
            name={name}
            onClickItem={onClickItem}
            idx={idx}
            selected={selected}
            type={type}
          />
        ))}
      </div>
    );
  }
);

export default SelectDropdown;
